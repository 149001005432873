<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="px-2 md:mx-auto mt-4">
        <div class="flex items-center justify-between w-60 mb-2">
          <label class="w-16 font-semibold">{{ $t("week") }}</label>
          <t-select
            class="w-44"
            v-model="filter.week"
            :options="weeks"
          ></t-select>
        </div>
        <div class="my-4">
          <strong>{{ $t("schedule") }}: </strong>
          <span>{{ horecaUser.user.name }}</span>
        </div>
        <table class="w-full mt-6">
          <tr
            class="border-t border-gray-300 odd:bg-gray-100"
            v-for="(sched, index) in userFullSchedules"
            :key="index"
          >
            <td class="p-3 text-sm">{{ sched.start | dayOfWeek }}</td>
            <td class="p-3 text-sm">{{ sched.start | date }}</td>
            <td class="p-3 text-sm">
              {{ duration(sched.start, sched.ending) }}
            </td>
            <td class="p-3 text-sm">{{ sched.job_name }}</td>
          </tr>
        </table>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { mapActions, mapGetters } from "vuex";
import moment from "@/utils/moment-utc";
import { getWeekOptions, getDayTranslation } from "@/utils/misc";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
  }),
  mounted() {
    console.log("DEBUG: ", moment().day());
    console.log("DEBUG: ", this.userFullSchedules);
  },
  methods: {
    ...mapActions({
      getUserFullSchedules: actions.FULLSCHEDULES_FETCH_USER_SCHEDULES_ACTION,
    }),
    duration(start, end) {
      const startTime = moment(start).format("HH:mm");
      const endTime = moment(end).format("HH:mm");
      if (endTime === "07:00") {
        return `${startTime} - ${this.$i18n.t("call")}`;
      }

      if (endTime === "02:00") {
        return `${startTime} - ${this.$i18n.t("close")}`;
      }

      return `${startTime} - ${endTime}`;
    },
  },
  computed: {
    ...mapGetters({
      filter: getters.SESSION_MAIN_FILTER_GETTER,
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      horecaUser: getters.AUTH_USER_GETTER,
      userFullSchedules: getters.FULLSCHEDULES_USER_SCHEDULES_GETTER,
    }),
    weeks() {
      return getWeekOptions(this.isAdminOrSuperUser);
    },
  },
  watch: {
    "filter.week": {
      deep: true,
      async handler(oldWeek, newWeek) {
        if (oldWeek === newWeek) {
          return;
        }

        this.loading = true;

        try {
          await this.getUserFullSchedules({
            from: this.filter.week,
            until: moment(this.filter.week).add(6, "d").format("YYYY-MM-DD"),
            userId: this.horecaUser.user.id,
          });
        } catch (error) {
          console.error("DEBUG: watch filters", error);
        }

        this.loading = false;
      },
    },
  },
  filters: {
    date(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    dayOfWeek(date) {
      return getDayTranslation(moment(date).day());
    },
  },
};
</script>

<style lang="scss" scoped></style>
